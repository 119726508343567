function LinkedinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0,0,256,256"
      width="100px"
      height="100px"
    >
      <g>
        <g transform="scale(2.56,2.56)">
          <path
            className="blueParticle"
            d="M13,27c-1.10457,0 -2,0.89543 -2,2c0,1.10457 0.89543,2 2,2c1.10457,0 2,-0.89543 2,-2c0,-1.10457 -0.89543,-2 -2,-2z"
            fill="#b7fae6"
          />
          <path
            className="yellowParticle"
            d="M77,12c-0.55228,0 -1,0.44772 -1,1c0,0.55228 0.44772,1 1,1c0.55228,0 1,-0.44772 1,-1c0,-0.55228 -0.44772,-1 -1,-1z"
            fill="#ff9900"
          />
          <path
            d="M50,13c-20.43454,0 -37,16.56546 -37,37c0,20.43454 16.56546,37 37,37c20.43454,0 37,-16.56546 37,-37c0,-20.43454 -16.56546,-37 -37,-37z"
            fill="#b7fae6"
          />
          <path
            className="yellowParticle"
            d="M83,11c-2.20914,0 -4,1.79086 -4,4c0,2.20914 1.79086,4 4,4c2.20914,0 4,-1.79086 4,-4c0,-2.20914 -1.79086,-4 -4,-4z"
            fill="#ff9900"
          />
          <path
            className="blueParticle"
            d="M87,22c-1.10457,0 -2,0.89543 -2,2c0,1.10457 0.89543,2 2,2c1.10457,0 2,-0.89543 2,-2c0,-1.10457 -0.89543,-2 -2,-2z"
            fill="#b7fae6"
          />
          <path
            className="yellowParticle"
            d="M81,74c-1.10457,0 -2,0.89543 -2,2c0,1.10457 0.89543,2 2,2c1.10457,0 2,-0.89543 2,-2c0,-1.10457 -0.89543,-2 -2,-2zM15,59c-2.20914,0 -4,1.79086 -4,4c0,2.20914 1.79086,4 4,4c2.20914,0 4,-1.79086 4,-4c0,-2.20914 -1.79086,-4 -4,-4z"
            fill="#ff9900"
          />
          <path
            className="blueParticle"
            d="M25,85c-1.10457,0 -2,0.89543 -2,2c0,1.10457 0.89543,2 2,2c1.10457,0 2,-0.89543 2,-2c0,-1.10457 -0.89543,-2 -2,-2z"
            fill="#b7fae6"
          />
          <g>
            <path
              d="M50,25.625c-13.39291,0 -24.25,10.85709 -24.25,24.25c0,13.39291 10.85709,24.25 24.25,24.25c13.39291,0 24.25,-10.85709 24.25,-24.25c0,-13.39291 -10.85709,-24.25 -24.25,-24.25z"
              fill="#a3a3cd"
            />
            <path
              d="M50,74.825c-13.757,0 -24.95,-11.192 -24.95,-24.95c0,-13.758 11.193,-24.95 24.95,-24.95c13.757,0 24.95,11.192 24.95,24.95c0,13.758 -11.193,24.95 -24.95,24.95zM50,26.325c-12.985,0 -23.55,10.564 -23.55,23.55c0,12.986 10.565,23.55 23.55,23.55c12.985,0 23.55,-10.564 23.55,-23.55c0,-12.986 -10.565,-23.55 -23.55,-23.55z"
              fill="#472b29"
            />
          </g>
          <g fill="#000417" className="darkCirle">
            <path d="M50,29.167c-11.32184,0 -20.5,9.17816 -20.5,20.5c0,11.32184 9.17816,20.5 20.5,20.5c11.32184,0 20.5,-9.17816 20.5,-20.5c0,-11.32184 -9.17816,-20.5 -20.5,-20.5z" />
          </g>
          <g fill="#472b29" className="outlinePart">
            <path d="M69.424,44.625c-0.214,0 -0.412,-0.139 -0.478,-0.354c-0.088,-0.287 -0.183,-0.571 -0.284,-0.853c-0.392,-1.094 -0.886,-2.159 -1.47,-3.169c-0.139,-0.238 -0.057,-0.545 0.182,-0.683c0.239,-0.141 0.545,-0.057 0.683,0.183c0.614,1.061 1.134,2.182 1.546,3.331c0.106,0.297 0.206,0.595 0.298,0.897c0.081,0.264 -0.067,0.544 -0.332,0.625c-0.048,0.016 -0.097,0.023 -0.145,0.023z" />
          </g>
          <g fill="#472b29" className="outlinePart">
            <path d="M50,70.75c-11.511,0 -20.875,-9.337 -20.875,-20.813c0,-11.476 9.364,-20.812 20.875,-20.812c5.975,0 11.674,2.56 15.636,7.023c0.299,0.337 0.588,0.685 0.865,1.041c0.169,0.218 0.13,0.531 -0.087,0.701c-0.218,0.171 -0.532,0.131 -0.702,-0.088c-0.264,-0.339 -0.54,-0.669 -0.824,-0.99c-3.772,-4.25 -9.199,-6.688 -14.888,-6.688c-10.959,0 -19.875,8.888 -19.875,19.813c0,10.925 8.916,19.813 19.875,19.813c10.959,0 19.875,-8.888 19.875,-19.813c0,-0.995 -0.075,-1.996 -0.222,-2.973c-0.041,-0.272 0.147,-0.527 0.42,-0.568c0.278,-0.045 0.528,0.147 0.569,0.42c0.154,1.025 0.233,2.076 0.233,3.121c0,11.476 -9.364,20.813 -20.875,20.813z" />
          </g>
          <g>
            <path
              className="contentPart"
              d="M60.5,51.6v6.9h-4.1l-0.1,-6.9c0,-1.7 -0.8,-3 -2.4,-3c-1.2,0 -1.9,0.8 -2.2,1.6c-0.1,0.3 -0.1,0.7 -0.1,1v7.2h-4.2c0,0 0.1,-11.7 0,-12.9h4.2v2v0v0v0c0.6,-1 1.6,-2.1 3.9,-2.1c2.9,0.1 5,1.9 5,6.2z"
              fill="#fdfcee"
            />
            <path
              className="contentPart"
              d="M60.5,59h-4.1c-0.3,0 -0.5,-0.2 -0.5,-0.5l-0.1,-6.9c0,-0.7 -0.2,-2.5 -1.9,-2.5c-1.1,0 -1.6,0.9 -1.8,1.3c0,0.1 -0.1,0.4 -0.1,0.9v7.2c0,0.3 -0.2,0.5 -0.5,0.5h-4.2c-0.1,0 -0.3,-0.1 -0.4,-0.1c-0.1,-0.1 -0.1,-0.2 -0.1,-0.4c0,-0.1 0.1,-11.7 0,-12.9c0,-0.1 0,-0.3 0.1,-0.4c0.1,-0.1 0.2,-0.2 0.4,-0.2h4.2c0.3,0 0.5,0.2 0.5,0.5v0.6c0.9,-0.8 2,-1.2 3.4,-1.2c1.6,0 2.8,0.5 3.8,1.4c1.1,1.1 1.7,2.9 1.7,5.2v6.9c0.1,0.4 -0.1,0.6 -0.4,0.6zM56.9,58h3.1v-6.4c0,-2 -0.5,-3.5 -1.4,-4.5c-0.7,-0.7 -1.8,-1.1 -3,-1.1c-2.1,0 -3,1 -3.5,1.8c-0.2,0.4 -0.7,0.4 -0.9,0.1c-0.1,-0.1 -0.1,-0.3 0,-0.4v-1.4h-3.2c0,2.2 0,9.9 0,11.9h3.2v-6.7c0,-0.5 0.1,-0.9 0.2,-1.2c0.5,-1.2 1.5,-1.9 2.7,-1.9c1.8,0 2.9,1.3 2.9,3.5z"
              fill="#472b29"
            />
          </g>
          <g>
            <path
              className="contentPart"
              d="M42.4,39.8c-1.2,0 -2,0.8 -2,2c0,1.1 0.8,2 2,2v0c1.3,0 2,-0.9 2,-2c0,-1.2 -0.8,-2 -2,-2z"
              fill="#fdfcee"
            />
            <path
              className="contentPart"
              d="M42.4,44.2c-1.5,0 -2.5,-1 -2.5,-2.5c0,-1.5 1.1,-2.5 2.5,-2.5c1.4,0 2.5,1 2.5,2.4c0,1.6 -1.1,2.6 -2.5,2.6zM42.4,40.3c-0.9,0 -1.5,0.6 -1.5,1.5c0,0.9 0.6,1.5 1.5,1.5c0.9,0 1.6,-0.6 1.6,-1.5c-0.1,-0.9 -0.7,-1.5 -1.6,-1.5z"
              fill="#472b29"
            />
          </g>
          <g>
            <path
              className="contentPart"
              d="M40.4,45.6h4.2v12.9h-4.2z"
              fill="#fdfcee"
            />
            <path
              className="contentPart"
              d="M44.6,59h-4.2c-0.3,0 -0.5,-0.2 -0.5,-0.5v-12.9c0,-0.3 0.2,-0.5 0.5,-0.5h4.2c0.3,0 0.5,0.2 0.5,0.5v12.9c0,0.3 -0.2,0.5 -0.5,0.5zM40.9,58h3.2v-11.9h-3.2z"
              fill="#472b29"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LinkedinIcon;
