function GirlIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M23.912,20.027l-1.057-5.96a3.477,3.477,0,0,0-1-5.793A10,10,0,0,0,2.226,7.9L1.5,12s1.607,0,1.778-.013a12.2,12.2,0,0,0,4.9-1.191A1.484,1.484,0,0,0,8,11.5a1.5,1.5,0,1,0,1.637-1.484A16.531,16.531,0,0,0,16.121,3.14a7.966,7.966,0,0,1,3.833,6.009l.078.746.738.133A1.5,1.5,0,0,1,20.5,13H19.351l-.22.624C18.214,16.222,15.13,19,12,19a8.358,8.358,0,0,1-7-5H1.147L.065,20.115A3.238,3.238,0,0,0,3.237,24L9,24V20.408A8.2,8.2,0,0,0,12,21a8.218,8.218,0,0,0,3-.591V24h5.762a3.217,3.217,0,0,0,2.56-1.256A3.172,3.172,0,0,0,23.912,20.027Z" />
      <circle cx="14.5" cy="11.5" r="1.5" />
    </svg>
  );
}

export default GirlIcon;
